@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Carousel styles */
.slick-slider {
  height: 100vh;
}

.slick-list,
.slick-track,
.slick-slide,
.slick-slide > div {
  height: 100%;
}

.slick-dots {
  bottom: 2rem !important;
  z-index: 20;
}

.slick-dots li {
  margin: 0 6px !important;
  width: 10px !important;
  height: 10px !important;
  transition: all 0.3s ease;
}

.slick-dots li button {
  width: 10px !important;
  height: 10px !important;
  padding: 0 !important;
}

.slick-dots li button:before {
  content: '' !important;
  width: 10px !important;
  height: 10px !important;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  opacity: 1 !important;
  transition: all 0.3s ease;
}

.slick-dots li.slick-active {
  transform: scale(1.2);
}

.slick-dots li.slick-active button:before {
  background: white !important;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.5s ease-out forwards;
}

/* Hero section */
.hero-section {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  opacity: 0.9;
}

/* Utility classes */
.container {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}